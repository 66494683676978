
#nav-container{
	position: fixed;
	top: 6em;
	right: 6em;
	margin:-3em;
    z-index: 20000;
}

/*toggle-icon*/
.toggle-icon {
	display: block;
	position: relative;
	width: 100%;
	margin: -2.2em auto 0 0.2em;
	-webkit-transition: margin 600ms;
	-moz-transition: margin 600ms;
	transition: margin 600ms;
	cursor: pointer;
}

.toggle-icon .bar {
	display: block;
	width: 3.8em;
	height: 0.2em;
	margin: 0.9em;
	border-right: 2.8em solid #fff;
	border-left: 0.6em solid #d6d6d6;
    mix-blend-mode: difference;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition-property: -webkit-transform, margin, border-right-color, box-shadow;
	-moz-transition-property: -moz-transform, margin, border-right-color, box-shadow;
	transition-property: transform, margin, border-right-color, box-shadow;
	-webkit-transition-duration: 600ms;
	-moz-transition-duration: 600ms;
	transition-duration: 600ms;
}
	.toggle-icon .bar:nth-of-type(1) {
		-webkit-transition: opacity 300ms;
		-moz-transition: opacity 300ms;
		transition: opacity 300ms;
	}
	.toggle-icon .bar:nth-of-type(2) {
		-webkit-transform: rotate(-180deg);
		-moz-transform: rotate(-180deg);
		-ms-transform: rotate(-180deg);
		-o-transform: rotate(-180deg);
		transform: rotate(-180deg);
	}

/*pushed*/
.pushed .toggle-icon {
	margin: -3.2em 0 0 0.7em;
}

	.pushed .bar:nth-of-type(1) {
		opacity: 0;
	}

	.pushed .bar:nth-of-type(2) {
		margin-left: 1.4em;
		-webkit-transform: rotate(-137deg) translateY(-1.5em);
		-moz-transform: rotate(-137deg) translateY(-1.5em);
		-ms-transform: rotate(-137deg) translateY(-1.5em);
		-o-transform: rotate(-137deg) translateY(-1.5em);
		transform: rotate(-137deg) translateY(-1.5em);
	}

	.pushed .bar:nth-of-type(3) {
		margin-left: 0.5em;
		-webkit-transform: rotate(-42deg);
		-moz-transform: rotate(-42deg);
		-ms-transform: rotate(-42deg);
		-o-transform: rotate(-42deg);
		transform: rotate(-42deg);
	}


.pushed .toggle-icon:hover .bar:nth-of-type(2) {
	border-right-color: #e9b8b9;
	box-shadow: 0.4em 0 0.6em 0.1em rgba(205,40,44,0.75);
}
.pushed .toggle-icon:hover .bar:nth-of-type(3) {
	border-right-color: #ddf1da;
	box-shadow: 0.4em 0 0.6em 0.1em rgba(83,228,68,0.65);
}

.navbar{
    position: fixed;
    top: -200vh;
    left: 0;
    right: 0;
    max-height: 100vh;
    height: 100vh;
    width: 100vw;
    z-index: 1999;
    background-color: black;
    opacity: 0;
    display: flex;
    flex-direction: column;

    .navbar-name{
        display: flex;
        flex: 1;
        font-size: 20rem;
        color: #707070;
        justify-content: center;
        align-items: center;
        font-family: 'MonteCarlo', cursive;

        @media screen and (max-width: 980px) {
           font-size: 16rem;
        }
        @media screen and (max-width: 720px) {
            font-size: 11rem;
         }
         @media screen and (max-width: 560px) {
            font-size: 8rem;
         }
         @media screen and (max-width: 400px) {
            font-size: 6rem;
         }
    }
    
    .navbar-navs{
        display: flex;
        flex: 1;
        flex-grow: 1;
        flex-direction: row;
        flex-wrap: wrap;
        color: azure;
        font-family: 'Oranienbaum', serif;
        
        &-nav{
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: flex-start;
            padding-top: 3rem;
            font-size: 8rem;
            border-top: 1px solid #707070;

        &:nth-child(1){
            border-right: 1px solid #707070;
        }
        
        &:nth-child(2){
            border-right: 1px solid #707070;
        }

        &:nth-child(3){
        }       
        
        }
    }
 
}