.App {
  height: 100%;
  width: 100%;  
  background-color: black;
  
}

html body{
  height: 100%;
  overflow: auto;
}
section{  
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}
.second .line{
  opacity: 0;
}

section:not(.first) {
  background-color: #EBE8E2;
  opacity: 0.3;
  visibility: hidden;
  transform: scale(0.8);
} 

.arc-scroll{
  position: absolute;
  bottom: 4rem;
  right: 4rem;
  border-radius: 50%;
  border: 3px dotted whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18rem;
  height: 18rem;
  color: aliceblue;
  font-size: 2rem;

}
@media screen and (max-width: 542px) {
  .arc-scroll{
    width: 12rem;
      height: 12rem;
      right: 1rem;
  }
}

.section-img{
  width: 100vw;
    position: fixed;
    height: 100vh;
    z-index: -2;
    background: url(./images/main.jpg) center;
    background-size: cover;
    top: 0;
}

.inactive{
  opacity: 0;
  filter: blur(10px);
  z-index: -5;
  transform: translateY(20em) scaleY(1.5);
}

.active{
  opacity: 1;
  filter: blur(0px);
  transform:  scaleY(1);
  z-index: -2;
}

/* .preview-image{
  width: 100vw;
  opacity: 0;
  position: fixed;
  height: 100vh;
  z-index: -4;
  background: url('./images/indian.jpg') center;
  background-size: cover;
  top: 0;
  transform:  scale(0.7);
} */
/* .preview-image-2{
  width: 100vw;
  opacity: 0;
  position: fixed;
  height: 100vh;
  z-index: -4;
  background: url('./images/person.jpeg') center;
  background-size: cover;
  top: 0;
  transform:  scale(0.7);
} */

.preview-image-3{
  max-width: 60rem;
  width: 60rem;
  opacity: 0;
  position: fixed;
  height: 60rem;
  z-index: 5;
  filter: blur(10px);
  /* background: url('./images/mei.png') center; */
  background-size: cover;
  transform:  scale(1);
}

#stalker{
  position: fixed;
  width:25rem;
  height: 25rem;
  border: rgb(41, 32, 32);
  border-style: dotted;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 199;
  font-weight: bold;
  transition: 0.3s;
  /* transition-delay: 1.5s; */
  pointer-events: none;
  transition-timing-function: ease-out;

  
}
#stalker-arrow{
    font-size: 5rem;
    display: inline-block;
}

@media screen and (max-width: 542px) {
  #stalker{
    width: 14rem;
    height: 14rem;
  }
}

@media screen and (max-width: 542px) {
  .preview-image-3{
    width: 100vw;
  }
}
/* .section:nth-child(2){
  background:#61dafb;
  background-repeat: no-repeat;
  scroll-behavior: smooth;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;  
  opacity: 0;
  filter: blur(10px);
  z-index: -1;
  transform: translateY(20em) scaleY(1.5);
  
} */


/* .App:hover .section:nth-child(1){
  opacity: 0;
  filter: blur(10px);
  z-index: -1;
  transform: translateY(20em) scaleY(1.5);
}

.App:hover .section:nth-child(2){
  opacity: 1;
  filter: blur(0px);
  transform:  scaleY(1);
  z-index: 3;
}

.bottom-trigger{
  height: 3em;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.top-trigger{
  height: 3em;
} */