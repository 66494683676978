


.intro-overlay{

    .top{
        height: 120vh;
        position: absolute;
        width: 100%;
        z-index: 8;
        .overlay-top{
            position: absolute;
            height: 100%;
            width: 50vw;
            background-color: white;
            bottom: 0;
            left: 0;
            right: 0;
            @media screen and (max-width: 980px) {
                width: 100vw;
              }
            &:nth-child(2){
                left: 50%;
                @media screen and (max-width: 980px) {
                    display: none;
                }
            }
            
        }
    }

    .bottom{
        height: 50vh;
        position: absolute;
        width: 100%;
        z-index: 10;
        bottom: 0;
        .overlay-bottom{
            position: absolute;
            height: 100%;
            width: 50vw;
            background-color: black;
            bottom: 0;
            right: 50%;

            @media screen and (max-width: 980px) {
                right: 0;
                width: 100vw;
              }
            &:nth-child(2){
                right: 0;
                @media screen and (max-width: 980px) {
                    width: 100vw;
                    top: 100%;
                    right: 0;
                }
            }

        }

    }

}