.Me{
    background-color: black;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;

    .me-header-div{
        position: absolute;
        left: 0;
        top: 35%;
        z-index: 2;

        transform: rotate(-90deg) translateY(-50%);
        color: #EBE8E2;;
        .me-header-text{
            font-size: 14rem;
            line-height: 15rem;
            text-transform: uppercase;
            font-family: 'Bungee Shade', cursive;
            opacity: 0;

            @media screen and (max-width: 1500px) {
                font-size: 12rem;
                line-height: 13rem;
            }
            @media screen and (max-width: 1200px) {
                font-size: 10rem;
                line-height: 11rem;
            }
            @media screen and (max-width: 980px) {
                font-size: 8rem;
                line-height: 9rem;
            }
            @media screen and (max-width: 720px) {
                font-size: 6rem;
                line-height: 7rem;
            }
            @media screen and (max-width: 540px) {
                font-size: 5rem;
                line-height: 6rem;
            }
            @media screen and (max-width: 420px) {
                font-size: 4rem;
                line-height: 5rem;
            }
        }

        

        @media screen and (max-width: 980px) {
            left: -8%;
        }
       
    }
    
    .me-image-div{
        position: absolute;
        width: 50%;
        height: 100%;
        left: 40%;
        top: 0;
        z-index: 1;
        background-size: cover;
        background: url('../images/image.jpg') no-repeat center center;
        transform: rotate(-40deg);
        overflow: hidden;
        @media screen and (max-width: 1040px) {
            left: 0;
            width: 100%;
         }
    }
    .me-back{
        position: absolute;
        width: 100vw;
        height: 100vh;
        background-color: black;
        left: 0;
        top: 0;
        z-index: -2;
    }
    .me-info-div{
        font-size: 2rem;
        color: #999;
        position: absolute;
        top: 58rem;
        font-weight: bold;
        letter-spacing: 1px;
        word-spacing: 1.5px;
        font-family: 'Roboto Mono', monospace;
        left: 50rem;
        text-align: center;
        opacity: 0;
        z-index: 2;
        transform: rotate(-90deg) translateX(-48%) translateY(-50%);

        div span{
            color: aliceblue;
        }

        @media screen and (max-width: 1500px) {
           left: 40rem;
           top: 55%;
        }
        @media screen and (max-width: 1320px) {
            left: 40rem;
         }
        @media screen and (max-width: 1200px) {
        font-size: 1.6rem;
        left: 33rem;
        }
        @media screen and (max-width: 980px) {
           top: 70rem;
           left:7rem;
           font-size: 1.8rem;
        transform: rotate(0deg);
        }
        // @media screen and (max-width: 840px) {
        //     top: 80%;
        //     left: 28%;
        //     font-size: 2.2rem;
        //  }
        @media screen and (max-width: 720px) {
            top: 62rem;
            left: 4rem;
            font-size: 1.6rem;
        }
        @media screen and (max-width: 540px) {
            top: 50rem;
        left: 5rem;
        font-size: 1.6rem;
        }
        @media screen and (max-width: 420px) {
            font-size: 1.4rem;
            top: 48rem;
            left: 3rem;
        }
    }
    .me-section-head{
        position: absolute;
        top: 6rem;
        left: -6rem;
        font-size: 2rem;
        padding: 1rem 0rem;
        transform: rotate(-45deg);
        background-color: #EBE8E2;
        color: #999;
        text-align: center;
        width: 30rem;
        z-index: 2;
        overflow: hidden;
        width: 0;
        @media screen and (max-width: 540px) {
            padding: 0;
            top: 3rem;
            left: -9rem;
        }
    }

    
}