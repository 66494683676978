.portfolio{
    &-div{
        position: absolute;
        text-align: center;
        z-index: 9;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    

        .line{
            position: relative;
            margin-bottom: 0.5rem;
            width: 100vw;
           
            &:nth-child(1){
                // margin-left: -37em;
                //   margin-top: -45rem;
                // @media screen and (max-width: 1500px) {
                //     // margin-left: -25em;
                // }
                // @media screen and (max-width: 1200px) {
                //     margin-top: -37em;
                //     // margin-left: -20em;
                // }
                // @media screen and (max-width: 980px) {
                //     margin-top: -35em;
                //     // margin-left: -12em;
                // }
                // @media screen and (max-width: 720px) {
                //     margin-top: -35em;
                //     // margin-left: -9em;
                // }
                // @media screen and (max-width: 540px) {
                //     margin-top: -40em;
                //     // margin-left: -6em;
                // }
                // @media screen and (max-width: 420px) {
                //     margin-top: -30em;
                //     // margin-left: 0em;
                // }
                }
     
            .intro-text{
                font-size: 22rem;
                text-transform: uppercase;
                font-family: 'Oranienbaum', serif;
                color: rgb(8, 8, 8);                
                position: relative;
                @media screen and (max-width: 980px) {
                    font-size: 11em;
                }
                @media screen and (max-width: 720px) {
                    font-size: 8em;
                }
                @media screen and (max-width: 540px) {
                    font-size: 6em;
                }

                @media screen and (max-width: 420px) {
                    font-size: 5em;
                }          
                @media screen and (max-width: 310px) {
                    font-size: 4em;
                } 
                &-1{
                    font-size: 20rem;
                    color: white;
                }
            } 
            @media screen and (max-width: 1400px) {
                margin-top: 8rem;
            }                          
        }
        
        .line-2{
            position: relative;
            margin-bottom: 0.5rem;
            width: 100vw;
            margin-top: -7rem;
            // margin-left: -46rem;

            .intro-text{
                font-size: 6rem;
                color: rgb(8, 8, 8);                 
                text-transform: uppercase;
                font-family: 'Oranienbaum', serif;
                position: relative;
                @media screen and (max-width: 1500px) {
                    
                }
                @media screen and (max-width: 1200px) {
                    font-size: 5rem;
                }
                @media screen and (max-width: 980px) {
                    
                }
                @media screen and (max-width: 720px) {
                    font-size: 4.2rem;
                }
                @media screen and (max-width: 540px) {
                    font-size: 3.2rem;
                }
                @media screen and (max-width: 420px) {
                  
                }
            }
            @media screen and (max-width: 1500px) {
                // margin-left: -21em;
            }
            @media screen and (max-width: 1200px) {
                margin-top: -7rem;
                // margin-left: -19em;
            }
            @media screen and (max-width: 980px) {
                margin-top: -3em;
                // margin-left: -8.5em;
            }
            @media screen and (max-width: 720px) {
                margin-top: -3em;
                // margin-left: -6em;
            }
            @media screen and (max-width: 540px) {
                margin-top: -2em;
                // margin-left: -4em;
            }
            @media screen and (max-width: 420px) {
                margin-top: -2em;
                // margin-left: 0em;
            }
        } 
        
        .line-3{
            position: relative;
            margin-bottom: 0.5rem;
            width: 100vw;
            margin-top: -2rem;
            margin-left: -52.5rem;
            .intro-text{
                font-size: 4rem;
                color: rgb(8, 8, 8);                 
                text-transform: uppercase;
                font-family: 'Oranienbaum', serif;
                position: relative;
                @media screen and (max-width: 1500px) {
                    
                }
                @media screen and (max-width: 1200px) {
                    font-size: 3.2rem;
                }
                @media screen and (max-width: 980px) {
                    
                }
                @media screen and (max-width: 720px) {
                    font-size: 2.7rem;
                }
                @media screen and (max-width: 540px) {
                    font-size: 2.2rem;
                }
                @media screen and (max-width: 420px) {
                  font-size: 1.8rem;
                }
            }
            @media screen and (max-width: 1500px) {
                margin-left: -25.5em;
            }
            @media screen and (max-width: 1200px) {
                margin-top: -2rem;
                margin-left: -23.3em;
            }
            @media screen and (max-width: 980px) {
                margin-top: -1em;
                margin-left: -12.8em;
            }
            @media screen and (max-width: 720px) {
                margin-top: -1em;
                margin-left: -9.5em;
            }
            @media screen and (max-width: 540px) {
                margin-top: -1em;
                margin-left: -6.2em;
            }
            @media screen and (max-width: 420px) {
                margin-top: -1em;
                margin-left: 0em;
            }
        } 

        .line-4{
            position: relative;
            margin-bottom: 0.5rem;
            width: 100vw;
            margin-top: -1.5rem;
            margin-left: -64rem;
            z-index: 7;
            .intro-text{
                font-size: 3rem;
                color: rgb(8, 8, 8);                 
                text-transform: uppercase;
                font-family: 'Oranienbaum', serif;
                position: relative;
                @media screen and (max-width: 1500px) {
                    
                }
                @media screen and (max-width: 1200px) {
                    font-size: 3.2rem;
                }
                @media screen and (max-width: 980px) {
                    
                }
                @media screen and (max-width: 720px) {
                    font-size: 2.7rem;
                }
                @media screen and (max-width: 540px) {
                    font-size: 2.2rem;
                }
                @media screen and (max-width: 420px) {
                  font-size: 1.8rem;
                }
            }

            .intro-country{
                background: linear-gradient(90deg, rgba(241,158,12,1) 15%, rgba(241,241,241,1) 58%, rgba(0,255,38,1) 100%);
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
            }
            @media screen and (max-width: 1500px) {
                margin-left: -33.8em;
            }
            @media screen and (max-width: 1200px) {
                margin-top: -1.5rem;
                margin-left: -28.6em;
            }
            @media screen and (max-width: 980px) {
                margin-top: -1em;
                margin-left: -18.2em;
            }
            @media screen and (max-width: 720px) {
                margin-top: -1em;
                margin-left: -14em;
            }
            @media screen and (max-width: 540px) {
                margin-top: -0.8em;
                margin-left: -9.8em;
            }
            @media screen and (max-width: 420px) {
                margin-top: -0.7em;
                margin-left: 0em;
            }
        } 

        #portfolio-image{
            height: 270px;
            width: 500px;            
            margin-top: 2rem;
            overflow: hidden;
            background-position: center;
            background-repeat: no-repeat;
            transition: 0.4s;
            #portfolio-image-img{
                height: 250px;
                width: 100%;
            }

            @media screen and (max-width: 450px) {
                width: 100vw;
            }
        }


        .skills{
            position: absolute;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            height: 5rem;
            bottom: 5rem;
            left: 0;

            div[class^='skills-div'],div[class*='skills-div']{
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-size: 2.2rem;
                color: #FF513B;
                height: 100%;
                justify-content: center;
                padding: 1rem;
                @media screen and (max-width: 540px) {
                    font-size: 1.5rem;
                }

            }

            .skill-div-circle{
                position: absolute;
                width: 2rem;
                height: 2rem;
                background-color: #FF513B;
                border-radius: 50%;
                z-index: -1;
                opacity: 0;
            }
            
        }


    }

    a:hover, a:active{
        color: transparent;
    }

   
}
