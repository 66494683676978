

.Social{
    position: absolute;
    height: 38rem;
    width: 10rem;
    top: 7rem;
    right: 4rem;
    z-index: 2;
    
   background: black;
   filter: blur(10px) contrast(20);

    .social-icon, 
    .social-overlay,
    .social-facebook,
    .social-instagram,
    .social-linkedin
    {  
    position: absolute;
    top:  7rem;  
    right: 1rem;
    width: 6rem;
    height: 6rem;
    z-index: 2;
    border-radius: 50%;
    background-color: #EBE8E2;
    
    z-index: 11;
    }

    

    .social-facebook{
        top: 15rem;
        animation: drop-f 1.3s cubic-bezier(0.4, 0, 1, 1) ;
        
    }

    .social-instagram{
        top: 23rem;
        animation: drop-i 2.3s cubic-bezier(0.4, 0, 1, 1) ;
    }
    .social-linkedin{
        top: 31rem;
        animation: drop-i 2.5s cubic-bezier(0.4, 0, 1, 1) ;
    }
    .social-overlay{
        background-color: white;
        
        animation: drop 2s cubic-bezier(0.4, 0, 1, 1) ;
    }
    
    @keyframes drop {
        0% {top: 7rem}
        33.33% {top: 15rem }
        66.66% {top: 23rem; }
        100% {top: 31rem}
    }

    
    @keyframes drop-f {
        0% {opacity: 0;}
        100% {opacity: 1;}
    }

    @keyframes drop-i {
        0% {opacity: 0; }
        100% {opacity: 1; }
    }

    
    @keyframes drop-l {
        0% {opacity: 0;}
        100% {opacity: 1;}
    }
   
    @media screen and (max-width: 540px) {
        right: 0rem;
    }

}

.fa-file,
.fa-linkedin-in,
.fa-github-square,
.fa-envelope
 {
    position: absolute;
    top: 23rem;
    right: 6rem;
    z-index: 12;
    color: rgb(17, 14, 14);
    font-size: 4rem;
    animation: f-i 8s;

    @media screen and (max-width: 540px) {
        right: 2rem;
    }

    &:hover{
        color: whitesmoke;
    }
}

.fa-github-square{
    top: 31rem;
    transform: translateX(-10%);
}

.fa-file{
    top: 39rem;
    transform: translateX(-10%);
}

.fa-envelope{
    top: 15rem;
}

@keyframes f-i {
    0% {opacity: 0; }
    100% {opacity: 1; }
}
