.Archieve{
    scroll-behavior: smooth;
      overflow: hidden;
    .archieve-intro{
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0;
        padding: 0;

        #canvas1 {
            position: absolute;
            width: 100%;
            height: 100%;
          top: 0;
          left: 0;
            z-index: 0;
        }
        .arc-line{
            // position: relative;
            transform: translateY(-100%);
            .arc-text{
                position: relative;
                color: #555;
                margin-top: 0.5rem;
                font-size: 5rem;
                font-family: 'MonteCarlo', serif;  
                
                @media screen and (max-width: 560px) {
                    font-size: 4rem;
                }

                @media screen and (max-width: 410px) {
                    font-size: 3rem;
                }
            }


        }

        .arc-text-left{
            position: absolute;
            transform: rotate(-90deg);
            top: 12rem;
            left: 4rem;
            font-size: 4rem;
            font-family: 'Oranienbaum', serif;
            @media screen and (max-width: 980px) {
                left: 0rem;
            }
            @media screen and (max-width: 560px) {
                left: -3rem;
            }
            @media screen and (max-width: 410px) {
                top: 6rem;
                left: -5rem;
            }
        }
        .arc-text-right{
            position: absolute;
            bottom: 12rem;
            right: 4rem;
            font-size: 4rem;
            font-family: 'Oranienbaum', serif;

            @media screen and (max-width: 980px) {
                right: 2rem;
            }
            @media screen and (max-width: 560px) {
                bottom: 4rem;
            }
        }

        .arc-scroll{
            position: absolute;
            bottom: 4rem;
            left: 4rem;
            border-radius: 50%;
            border: 3px dotted whitesmoke;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 18rem;
            height: 18rem;
            color: aliceblue;
            font-size: 2rem;

            @media screen and (max-width: 410px) {
                width: 12rem;
                height: 12rem;
                left: 1rem;
            }
        }

    }


    .archieve-second{
        width: 100vw;
        height: auto;
        position: relative;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        margin: 0;
        padding: 0;
        .arc-sec-text{
            font-size: 12rem;
            margin-top: 4rem;
            color: aliceblue;
            font-family: 'Oranienbaum', serif;
        }

        

        .arc-sec-left{
            position: absolute;
            left: 0;
            width: 50%;
            top: 0;
            bottom: 0;
            z-index: -2;
            background-color: #111;
        }
        .arc-sec-right{
            position: absolute;
            left: 50%;
            width: 50%;
            top: 0;
            right: 0;
            bottom: 0;
            border-left: 0.5px solid rgb(85, 83, 83);
            z-index: -2;
            background-color: #111;


        }

        // .arc-sec-div{
        //     width: 400px;
        //     height: 220px;
        //     overflow: hidden;
        //     margin-top: 6rem;

        //     .arc-sec-div-img{
        //         width: 100%;
        //         height: 100%;
        //     }
        // }

        .sec-progress-bar{
            position: fixed;
            top: 0;
            left: 85%;
            background: rgba(252, 248, 248, 0.4);
            width: 5px;
            z-index: 1;
        }

        .sec-navigation-wrapper ul{
            margin-bottom: 12rem;
            list-style: none;
            // position: absolute;
            font-weight: 400;
            z-index: 8;
        }
        .sec-navigation-item{
            display: block;
            position: relative;
            user-select: none;
            margin: 120px 0;
            z-index: 8;

            &:hover{
                cursor: pointer;
            }
        }
        a{
            font-family: 'Road Rage', cursive;
            font-size: 16rem;
            text-decoration: none;
            color: rgba(255, 255, 255, 0.3);
            z-index: 8;
        }

        span{
            position: relative;
            display: block;
        }

        .sec-navigation-item span::before{
            width: 0;
            color: white;
            overflow: hidden;
            position: absolute;
            content: attr(data-text);
            transition: all 1s cubic-bezier(0.84, 0, 0.08, 0.99);
        }

        .sec-navigation-item span:hover::before{
            width: 100%;
        }

        .sec-project-preview-wrapper{
            width: 100%;
            height: 100vh;
            position: fixed;
            display: flex;
            justify-content: center;
        }

        .sec-project-preview{
            position: fixed;
            top: 12%;
            left: 50%;
            width: 0;
            height: 50rem;
            background: url('../images/blockchain.jpg') no-repeat 50% 50%;
            background-size: contain;
            overflow: hidden;
        }

        .sec-description-div{
            position: fixed;
            left: 0;
            top: 50%;
            display: flex;
            flex-direction: row;
            justify-content: center;

            
            .sec-description-div-desc{
                // transform: rotate(-90deg);
                font-family: 'Cosi Azure';
                font-size: 3rem;
                color: rgba(255, 255, 255, 0.3);

            }

        }
     
        div[class^="navigation-overlay"]{
            position: absolute;
            width: 100%;            
            height: 100%;
            top: 0;
            left: 0;
            z-index: 2;
            background-color:rgb(230, 224, 228) ;
        }

        div[class^="navigation-overlays"]{
            position: absolute;
            width: 100%;            
            height: 100%;
            top: 0;
            z-index: 1;
            background-color:#C7B198 ;
        }

        div[class^="navigation-v-overlays"]{
            position: absolute;
            bottom: 10%;
            width: 0.5rem;
            left: -1rem;
            height: 0;
            background-color: rgba(255, 255, 255, 0.3);;
        }

        @media (max-width: 900px) {
            .sec-progress-bar {
                display: none;
            }

            .sec-project-preview{
                left: 0;
                z-index: -1;
                background-size: cover;
                height: 15rem;
            }

            a{
                font-size: 10rem;
            }

            .sec-navigation-wrapper ul{
                // position: absolute;
                transform: translateX(0px);
            }
        }
    }


}

.sec-description-div-title{
    position: fixed;
    left: 4rem;
    top: 55%;
    // bottom: 0;
    transform: rotate(-90deg) translateY(-180%);
    font-family: 'Cosi Azure';
    font-size: 11rem;
    z-index: -1;
    color: rgba(255, 255, 255, 0.3);

    .sec-desc-span-title{
        position: relative;
    }

     &:hover{
         cursor: pointer;
     }
}

.sec-description-div-desc{
    position: fixed;
    top: 10%;
    right: 10%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: aliceblue;    
    font-family: 'MonteCarlo', serif; 
    flex-direction: column;
    font-size: 4rem;
    line-height: 5rem;
    opacity: 1;

    @media (max-width: 540px) {
        display: none;
    }
    div{
        opacity: 0;
    }
}

