.intro{
    &-div{
        position: absolute;
        text-align: center;
        z-index: 9;
       

        .line{
            position: relative;
            margin-bottom: 0.5rem;
            width: 100vw;
           
            &:nth-child(1){
                margin-left: -37em;
                margin-top: -5em;
                @media screen and (max-width: 1500px) {
                    margin-left: -25em;
                }
                @media screen and (max-width: 1200px) {
                    margin-top: 0em;
                    margin-left: -20em;
                }
                @media screen and (max-width: 980px) {
                    margin-top: 0em;
                    margin-left: -12em;
                }
                @media screen and (max-width: 720px) {
                    margin-top: 1em;
                    margin-left: -9em;
                }
                @media screen and (max-width: 540px) {
                    margin-top: 4em;
                    margin-left: -6em;
                }
                @media screen and (max-width: 420px) {
                    margin-top: 0em;
                    margin-left: 0em;
                }
                }
        //     &:nth-child(2){
        //         margin-left: 20em;
        //         @media screen and (max-width: 1500px) {
        //             margin-left: 12em;                
        //     }
        //         @media screen and (max-width: 1200px) {
        //         margin-left: 0;
        //         }
        // }
            .intro-text{
                font-size: 50rem;
                text-transform: uppercase;
                font-family: 'Oranienbaum', serif;
                color: rgb(8, 8, 8);                
                position: relative;
               
                @media screen and (max-width: 980px) {
                    font-size: 30em;
                }
                @media screen and (max-width: 720px) {
                    font-size: 25em;
                }
                @media screen and (max-width: 540px) {
                    font-size: 20em;
                }

                @media screen and (max-width: 420px) {
                    font-size: 20em;
                }          
                
                &-1{
                    font-size: 20rem;
                    color: white;
                }
            }                           
        }
        
        .line-2{
            position: relative;
            margin-bottom: 0.5rem;
            width: 100vw;
            margin-top: -17rem;
            margin-left: -46rem;

            .intro-text{
                font-size: 6rem;
                color: rgb(8, 8, 8);                 
                text-transform: uppercase;
                font-family: 'Oranienbaum', serif;
                position: relative;
                @media screen and (max-width: 1500px) {
                    
                }
                @media screen and (max-width: 1200px) {
                    font-size: 5rem;
                }
                @media screen and (max-width: 980px) {
                    
                }
                @media screen and (max-width: 720px) {
                    font-size: 4.2rem;
                }
                @media screen and (max-width: 540px) {
                    font-size: 3.2rem;
                }
                @media screen and (max-width: 420px) {
                  
                }
            }
            @media screen and (max-width: 1500px) {
                margin-left: -21em;
            }
            @media screen and (max-width: 1200px) {
                margin-top: -18rem;
                margin-left: -19em;
            }
            @media screen and (max-width: 980px) {
                margin-top: -11em;
                margin-left: -8.5em;
            }
            @media screen and (max-width: 720px) {
                margin-top: -9em;
                margin-left: -6em;
            }
            @media screen and (max-width: 540px) {
                margin-top: -7em;
                margin-left: -4em;
            }
            @media screen and (max-width: 420px) {
                margin-top: -7em;
                margin-left: 0em;
            }

            .intro-name{
                z-index: 11;
                
            }
        } 
        
        .line-3{
            position: relative;
            margin-bottom: 0.5rem;
            width: 100vw;
            margin-top: -2rem;
            margin-left: -52.5rem;
            .intro-text{
                font-size: 4rem;
                color: rgb(8, 8, 8);                 
                text-transform: uppercase;
                font-family: 'Oranienbaum', serif;
                position: relative;
                @media screen and (max-width: 1500px) {
                    
                }
                @media screen and (max-width: 1200px) {
                    font-size: 3.2rem;
                }
                @media screen and (max-width: 980px) {
                    
                }
                @media screen and (max-width: 720px) {
                    font-size: 2.7rem;
                }
                @media screen and (max-width: 540px) {
                    font-size: 2.2rem;
                }
                @media screen and (max-width: 420px) {
                  font-size: 1.8rem;
                }
            }
            @media screen and (max-width: 1500px) {
                margin-left: -25.5em;
            }
            @media screen and (max-width: 1200px) {
                margin-top: -2rem;
                margin-left: -23.3em;
            }
            @media screen and (max-width: 980px) {
                margin-top: -1em;
                margin-left: -12.8em;
            }
            @media screen and (max-width: 720px) {
                margin-top: -1em;
                margin-left: -9.5em;
            }
            @media screen and (max-width: 540px) {
                margin-top: -1em;
                margin-left: -6.2em;
            }
            @media screen and (max-width: 420px) {
                margin-top: -1em;
                margin-left: 0em;
            }
        } 

        .line-4{
            position: relative;
            margin-bottom: 0.5rem;
            width: 100vw;
            margin-top: -1.5rem;
            margin-left: -64rem;
            z-index: 7;
            .intro-text{
                font-size: 3rem;
                color: rgb(8, 8, 8);                 
                text-transform: uppercase;
                font-family: 'Oranienbaum', serif;
                position: relative;
                @media screen and (max-width: 1500px) {
                    
                }
                @media screen and (max-width: 1200px) {
                    font-size: 3.2rem;
                }
                @media screen and (max-width: 980px) {
                    
                }
                @media screen and (max-width: 720px) {
                    font-size: 2.7rem;
                }
                @media screen and (max-width: 540px) {
                    font-size: 2.2rem;
                }
                @media screen and (max-width: 420px) {
                  font-size: 1.8rem;
                }
            }

            .intro-country{
                background: linear-gradient(90deg, rgba(241,158,12,1) 15%, rgba(241,241,241,1) 58%, rgba(0,255,38,1) 100%);
                -webkit-text-fill-color: transparent;
                font-weight: bold;
                -webkit-background-clip: text;
            }
            @media screen and (max-width: 1500px) {
                margin-left: -33.8em;
            }
            @media screen and (max-width: 1200px) {
                margin-top: -1.5rem;
                margin-left: -28.6em;
            }
            @media screen and (max-width: 980px) {
                margin-top: -1em;
                margin-left: -18.2em;
            }
            @media screen and (max-width: 720px) {
                margin-top: -1em;
                margin-left: -14em;
            }
            @media screen and (max-width: 540px) {
                margin-top: -0.8em;
                margin-left: -9.8em;
            }
            @media screen and (max-width: 420px) {
                margin-top: -0.7em;
                margin-left: 0em;
            }
        } 
    }

   
}
