
.project-details{

    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -2;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .project-close {
        position: absolute;
        left: 4rem;
        top: 4rem;
        opacity: 1;
        font-size: 2rem;
        z-index: 201;
        transition: 0.4s ease;
        i{
            mix-blend-mode:difference;
        }
      }
      .project-close:hover {
        transform: rotate(360deg);
      }
    //   .project-close:before, .project-close:after {
    //     position: absolute;
    //     left: 15px;
    //     content: ' ';
    //     height: 33px;
    //     width: 2px;
    //     background-color: #333;
    //   }
    //   .project-close:before {
    //     transform: rotate(45deg);
    //   }
    //   .project-close:after {
    //     transform: rotate(-45deg);
    //   }

    

    .project-details-image-div{
        position: relative;
        width: 50vw;
        height: 100vh;
        overflow: hidden;
        background-color: #EBE8E2;
        .project-details-image{
            width: 100%;
            height: 100%;
        }

        @media screen and (max-width: 1000px) {
            margin: 0;
            height: 50vh;
            width: 100vw;
        }

        
    }

    .project-details-info-div{
        position: relative;
        width: 50vw;
        height: 100vh;
        overflow: hidden;
        background-color: #EBE8E2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5rem;
        font-size: 5rem;
        font-family: 'MonteCarlo', serif;
        @media screen and (max-width: 1000px) {
            margin: 0;
            height: 50vh;
            width: 100vw;
        }
        @media screen and (max-width: 760px) {
            font-size: 3rem;
        }
        @media screen and (max-width: 400px) {
            font-size: 2.2rem;
        }
    }
    .col-md-6{
        margin: 0;
        padding: 0;

        &:nth-child(2){
            display: flex;
            flex-direction: row;
        }

        .project-next{
            position: absolute;
            right: 3rem;
            top: 50%;
            font-size: 4rem;
            @media screen and (max-width: 1000px) {
                top: -5%
            }
        }
    }
    @media screen and (max-width: 1000px) {
        .row{
            margin: 0;
            padding: 0;
            width: 100vw;
            height: 100vh;
        }
        .col-md-6{
            margin: 0;
            padding: 0;
            height: 50vh;
        }
    }

}